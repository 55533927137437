import React from "react"
import { Link, graphql } from "gatsby"
import Layout from '../components/layout'
import SEO from "../components/seo";

const BlogIndex = ({ data }) => {
  const { edges: posts } = data.allMdx

  return (
    <Layout>
      <SEO title="Article Archive" />
        {posts.map(({ node: post }) => (
          <div key={post.id}>
            <Link to={post.fields.slug}>
              <strong>{post.frontmatter.title}</strong>
            </Link>
            <p>{post.excerpt}</p>
          </div>
        ))}
    </Layout>
  )
}

export const pageQuery = graphql`
  query blogIndex {
    allMdx(filter: {fileAbsolutePath: {regex: "/posts/"}}) {
      edges {
        node {
          id
          excerpt
          frontmatter {
            title
          }
          fields {
            slug
          }
        }
      }
    }
  }
`

export default BlogIndex